import React, { useMemo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores';
import {
  Button,
  Checkbox,
  Content,
  Header,
  Spacer,
} from '../components';
import lang from '../locale/locale';

export const Success = observer(() => {
  const commonStore = useStore('commonStore');
  const sessionStore = useStore('sessionStore');
  const { getCustomerGroups } = commonStore;

  const [confirmAuthorization, setConfirmAuthorization] = useState(false);

  // Translations for current locale
  const t = useMemo(() => lang[commonStore.locale], [commonStore.locale]);

  const localizedCustomerGroups = useMemo(() => {
    if (commonStore.customerGroups) {
      return commonStore.customerGroups
        // @ts-ignore
        .map((customerGroup) => t.customerGroup[customerGroup])
        .join(', ');
    }
    return '';
  }, [commonStore.customerGroups, t.customerGroup]);

  useEffect(() => {
    getCustomerGroups();
  }, [getCustomerGroups]);

  const handleCheckboxClick = () => {
    setConfirmAuthorization(!confirmAuthorization);
  };

  const handleAuthorizeOrganizationClick = () => {
    commonStore.authorizeOrganization();
  };

  const handleRevokeAuthorisationsClick = () => {
    commonStore.checkAndRevokeAuthorizations();
  };

  const handleCancel = () => {
    commonStore.cancelAndClear();
  };

  return (
    <div>
      <Header />
      <main>
        <Content>
          <h1>
            {t.strongIdentification.success.header} {sessionStore.organizationName}
          </h1>
          <p>
            {t.strongIdentification.success.info1} {sessionStore.organizationName}{' '}
            {t.strongIdentification.success.info2}{' '}
            <a
              rel="nofollow noreferrer noopener"
              target="_blank"
              href={t.strongIdentification.success.infoLink}
            >
              {t.strongIdentification.success.infoLinkText}
            </a>
            . {t.strongIdentification.success.info3}
            {sessionStore.organizationName}
            {t.strongIdentification.success.info4}
          </p>
          {localizedCustomerGroups && (
            <p>
              {t.strongIdentification.success.customerGroupInfo}{' '}
              {localizedCustomerGroups}{' '}
            </p>
          )}
          <p>
            {t.strongIdentification.success.info5} {sessionStore.organizationName}
            {t.strongIdentification.success.info6} {sessionStore.organizationName}
            {t.strongIdentification.success.info7}
          </p>
          <Spacer />
          <Checkbox
            defaultChecked={confirmAuthorization}
            onClick={handleCheckboxClick}
            title={`${t.strongIdentification.success.authorize1} ${sessionStore.organizationName}${t.strongIdentification.success.authorize2}`}
          />
          <Spacer />
          <Button
            disabled={!confirmAuthorization}
            primary
            onClick={handleAuthorizeOrganizationClick}
          >
            {t.authorize}
          </Button>
          <Button
            disabled={commonStore.isLoading}
            onClick={handleRevokeAuthorisationsClick}
          >
            {t.revokeAuthorizations}
          </Button>
          <Button onClick={handleCancel}>{t.cancel}</Button>
        </Content>
      </main>
    </div>
  );
});
