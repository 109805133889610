import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import appConfig from '../config';

const API_PATHS: { [key: string]: string } = Object.freeze({
  getOrganizationDetails: appConfig.ORGANIZATION_DETAILS_URL,
  loginByCode: appConfig.AUTHORIZATION_LOGIN_URL,
  getCustomerGroups: appConfig.CUSTOMER_GROUPS_URL,
  authorizeOrganization: appConfig.AUTHORIZE_ORGANIZATION_URL,
  checkAndRevokeAuthorizations: appConfig.CHECK_AND_REVOKE_AUTHORIZATIONS_URL,
});

const api = axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getOrganizationDetails = async (
  organizationId: string,
): Promise<AxiosResponse> => api.get(
  API_PATHS.getOrganizationDetails,
  { params: { organizationId } },
);

export const loginByCode = async (
  code: string,
): Promise<AxiosResponse> => {
  const queryString = stringify({
    authCode: code,
    redirect_uri: `${appConfig.BASE_URL}/authcallback`,
  });
  return api.post(`${API_PATHS.loginByCode}?${queryString}`, undefined);
};

export const getCustomerGroups = async (
  token: string,
): Promise<AxiosResponse> => api.get(
  API_PATHS.getCustomerGroups,
  { headers: { Authorization: `Bearer ${token}` } },
);

export const authorizeOrganization = async (
  organizationId: string,
  token: string,
): Promise<AxiosResponse> => api.post(
  API_PATHS.authorizeOrganization,
  null,
  { params: { organizationId }, headers: { Authorization: `Bearer ${token}` } },
);

export const checkAndRevokeAuthorizations = async (
  organizationId: string,
  token: string,
): Promise<AxiosResponse> => api.post(
  API_PATHS.checkAndRevokeAuthorizations,
  { organizationId },
  { headers: { Authorization: `Bearer ${token}` } },
);
