import React, { useMemo, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores';
import { Button, Content, Header } from '../components';
import lang from '../locale/locale';
import { handleRedirect } from '../utils';

export const Authorized = observer(() => {
  const commonStore = useStore('commonStore');

  // Translations for current locale
  const t = useMemo(() => lang[commonStore.locale], [commonStore.locale]);

  const timeout = useRef(0);

  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      if (commonStore.redirectUrl) {
        handleRedirect(commonStore.redirectUrl, commonStore.queryState);
      }
    }, 1000);
    return () => window.clearTimeout(timeout.current);
  }, [commonStore.queryState, commonStore.redirectUrl]);

  const handleReturnClick = () => {
    window.clearTimeout(timeout.current);
    handleRedirect(commonStore.redirectUrl, commonStore.queryState);
  };

  return (
    <div>
      <Header />
      <main>
        <Content>
          <h1>{t.authorized.header}</h1>
          <p>{t.authorized.message}</p>
          {commonStore.redirectUrl && (
            <Button onClick={handleReturnClick}>{t.return}</Button>
          )}
        </Content>
      </main>
    </div>
  );
});
