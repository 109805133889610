import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores';
import { Button, Header, Content } from '../components';
import lang from '../locale/locale';

export const Error = observer(() => {
  const commonStore = useStore('commonStore');

  // Translations for current locale
  const t = useMemo(() => lang[commonStore.locale], [commonStore.locale]);

  const handleErrorRedirect = () => {
    const stateRegex = /state/g;
    const foundState = commonStore.redirectUrl.match(stateRegex);
    const regex = /\?|%3F/g;
    const found = !!commonStore.redirectUrl.match(regex);
    const getUrl = (isFound: boolean) => {
      if (isFound) {
        return `${commonStore.redirectUrl}&error=${
          commonStore.error
        }&error_description=${commonStore.errorDescription}${
          commonStore.queryState && !foundState
            ? `&state=${commonStore.queryState}`
            : ''
        }`;
      }
      return `${commonStore.redirectUrl}?error=${
        commonStore.error
      }&error_description=${commonStore.errorDescription}${
        commonStore.queryState && !foundState
          ? `&state=${commonStore.queryState}`
          : ''
      }`;
    };
    window.location.assign(getUrl(found));
  };

  return (
    <div>
      <Header />
      <main>
        <Content>
          <h1>{commonStore.error}</h1>
          <p>{commonStore.errorDescription}</p>

          {commonStore.redirectUrl && (
            <Button onClick={handleErrorRedirect}>{t.return}</Button>
          )}
        </Content>
      </main>
    </div>
  );
});
