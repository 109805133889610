import styled from 'styled-components';

const Spinner = (): JSX.Element => (
  <Container>
    <Animation>
      <svg viewBox="0 0 80 80" version="1.1">
        <path fill="#007ac9" d="M40,80C17.944,80,0,62.056,0,40C0,17.944,17.944,0,40,0c22.056,0,40,17.944,40,40c0,2.209-1.791,4-4,4s-4-1.791-4-4C72,22.355,57.645,8,40,8C22.355,8,8,22.355,8,40c0,17.645,14.355,32,32,32c2.209,0,4,1.791,4,4S42.209,80,40,80z" />
      </svg>
    </Animation>
  </Container>
);

export default Spinner;

const Container = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Animation = styled.div`
  display: inline-block;
  height: 80px;
  width: 80px;
  top: 50%;
  left: 50%;
  animation: spin 0.5s linear infinite;
  svg {
    height: 80px;
    width: 80px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;
