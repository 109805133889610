import React from 'react';
import ReactDOM from 'react-dom';
import { StoresProvider, stores } from './stores/Stores';
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <StoresProvider value={stores}>
      <App />
    </StoresProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
