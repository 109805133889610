import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores';
import lang from '../locale/locale';

export const Fail = observer(() => {
  const commonStore = useStore('commonStore');

  // Translations for current locale
  const t = useMemo(() => lang[commonStore.locale], [commonStore.locale]);

  useEffect(() => {
    commonStore.setError(
      t.error.incompleteIdentification.header,
      t.error.incompleteIdentification.description,
    );
  }, [
    commonStore,
    t.error.incompleteIdentification.description,
    t.error.incompleteIdentification.header,
  ]);

  return null;
});
