const config = {
  HSLID_REGISTER_URL: process.env.REACT_APP_HSLID_REGISTER_URL || '',
  OPENID_CONNECT_URL: process.env.REACT_APP_OPENID_CONNECT_URL || '',
  OPENID_CONNECT_CLIENT_ID:
    process.env.REACT_APP_OPENID_CONNECT_CLIENT_ID || '',
  ORGANIZATION_ID: process.env.REACT_APP_ORGANIZATION_ID || '',
  AUTHORIZATION_LOGIN_URL:
    process.env.REACT_APP_AUTHORIZATION_LOGIN_URL || '',
  ORGANIZATION_DETAILS_URL:
    process.env.REACT_APP_ORGANIZATION_DETAILS_URL || '',
  AUTHORIZE_ORGANIZATION_URL:
    process.env.REACT_APP_AUTHORIZE_ORGANIZATION_URL || '',
  CHECK_AND_REVOKE_AUTHORIZATIONS_URL:
    process.env.REACT_APP_CHECK_AND_REVOKE_AUTHORIZATIONS_URL || '',
  CUSTOMER_GROUPS_URL:
    process.env.REACT_APP_CUSTOMER_GROUPS_URL || '',
  BASE_URL: process.env.REACT_APP_BASE_URL || '',
  BASE_NAME: process.env.REACT_APP_BASE_NAME || '',
};

export default config;
