// Session Store is autosaved and synced to browsers SessionStorage
import { observable, action, makeObservable } from 'mobx';
import { storeAutoSave } from '../utils';

export interface ISessionStore {
  accessToken: string;
  idToken: string;
  organizationName: string;
  setAccessToken: (accessToken: string) => void;
  setIdToken: (idToken: string) => void;
  setOrganizationName: (organizationName: string) => void;
}

class SessionModel implements ISessionStore {
  accessToken: ISessionStore['accessToken'] = '';

  idToken: ISessionStore['idToken'] = '';

  organizationName: ISessionStore['organizationName'] = '';

  constructor() {
    makeObservable(this, {
      accessToken: observable,
      idToken: observable,
      organizationName: observable,
      setAccessToken: action,
      setIdToken: action,
      setOrganizationName: action,
    });
    // initial value setup must be placed here before update by autoSave
    this.accessToken = '';
    this.idToken = '';
    this.organizationName = '';
    storeAutoSave(this, 'sessionStore');
  }

  /**
   * Updates the accessToken
   */
  setAccessToken = (accessToken: string) => {
    this.accessToken = accessToken;
  };

  /**
   * Updates the idToken
   */
  setIdToken = (idToken: string) => {
    this.idToken = idToken;
  };

  /**
   * Updates the organizationName
   */
  setOrganizationName = (organizationName: string) => {
    this.organizationName = organizationName;
  };
}

const SessionStore = new SessionModel();
export default SessionStore;
