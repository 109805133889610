const lang = {
  en: {
    landing: {
      header: 'Authorization to act on another person\'s behalf',
      p1: 'You are about to authorize ',
      p2: ' to purchase personal HSL tickets on your behalf. In order to complete the authorization process, you need to have an HSL account and you need to be strongly authenticated. Authentication is used to verify your home municipality and to identify you. You can strongly authenticate with you online banking credentials or mobile ID.',
      p3: 'If you already have an HSL account, log in. If need be, you will be asked to strongly authenticate.',
      p4: 'If you do not yet have an HSL account, create a new account. Once you have created and confirmed your account, you will be directed to strong authentication.',
    },
    strongIdentification: {
      success: {
        header: 'Authorize ',
        info1: 'You can now authorize ',
        info2: 'to buy personal HSL tickets on your behalf. By completing the authorization, you agree to ',
        infoLinkText: 'HSL Public Transport Conditions of Carriage and Ticket Terms and Conditions',
        infoLink: 'https://www.hsl.fi/en/hsl/new-terms-of-use/hsl-public-transport-conditions-of-carriage-and-ticket-terms-and-conditions',
        info3: 'Once the authorization is in place ',
        info4: ' will receive an encrypted identifier allowing them to purchase tickets.',
        info5: 'The authorization is valid for one year. Every time ',
        info6: ' buys a personal HSL ticket on your behalf, validity of the authorization is renewed for one year from the date of ordering the ticket. You can revoke existing authorizations for ',
        info7: ' in this authorization service.',
        authorize1: 'I authorize',
        authorize2: ' to buy personal HSL tickets on my behalf',
        customerGroupInfo: 'Your customer type(s): ',
      },
    },
    authorized: {
      header: 'Authorization successful',
      message: 'The authorization was successfully saved. The authorization is valid for one year after which you must renew it through this authorization service. You can revoke existing authorizations in this authorization service.',
    },
    error: {
      userCanceled: {
        header: 'Canceled',
        message: 'User canceled action.',
      },
      invalidToken: 'Token is invalid or has expired',
      incompleteIdentification: {
        header: 'Strong authentication failed',
        description: 'You cannot grant an authorization without strong electronic authentication.',
      },
    },
    login: 'Log in',
    register: 'Register',
    cancel: 'Cancel',
    authorize: 'Authorize',
    revokeAuthorizations: 'Revoke authorization',
    return: 'Return',
    customerGroup: {
      adult: 'Adult',
      child: 'Child 13-17 yrs.',
      student: 'Student',
      senior: 'Adult 70+',
    },
  },
  fi: {
    landing: {
      header: 'Puolesta-asiointivaltuutus',
      p1: 'Olet antamassa',
      p2: ':lle valtuutuksen ostaa puolestasi HSL:n henkilökohtaisia lippuja. Valtuutuksen antamisen edellytyksenä on, että sinulla on HSL-tunnus ja olet tunnistautunut vahvasti. Tunnistautumisella varmistetaan kotikuntasi ja henkilöllisyytesi. Vahvan tunnistautumisen voit tehdä pankkitunnuksilla tai mobiilivarmenteella.',
      p3: 'Jos sinulla on jo HSL-tunnus, kirjaudu sisään. Sisäänkirjautumisen jälkeen sinut ohjataan tunnistautumaan vahvasti.',
      p4: 'Jos sinulla ei vielä ole HSL-tunnusta, luo uusi tunnus. Tunnuksen luomisen ja vahvistamisen jälkeen sinut ohjataan tunnistautumaan vahvasti.',
    },
    strongIdentification: {
      success: {
        header: 'Valtuuta ',
        info1: 'Voit nyt valtuuttaa ',
        info2: 'ostamaan puolestasi HSL:n henkilökohtaisia lippuja. Valtuutuksen myötä sitoudut noudattamaan ',
        infoLinkText: 'HSL:n joukkoliikenteen yleiset matkustus- ja lippuehdot',
        infoLink: 'https://www.hsl.fi/hsl/kayttoehdot-uudet/hsln-joukkoliikenteen-yleiset-matkustus--ja-lippuehdot',
        info3: 'Valtuutuksen jälkeen ',
        info4: ':lle toimitetaan lippuostoon mahdollistava salattu tunniste.',
        info5: 'Valtuutus on voimassa vuoden. Joka kerta, kun ',
        info6: ' ostaa henkilökohtaisen HSL-lipun puolestasi, valtuutuksen voimassaoloaika päivittyy yhden vuoden päähän lipun tilaamisesta. Voit perua voimassaolevan valtuutuksen ',
        info7: ':lle tässä valtuutuspalvelussa ',
        authorize1: 'Annan',
        authorize2: ':lle valtuutuksen ostaa puolestani HSL:n henkilökohtaisia lippuja.',
        customerGroupInfo: 'Asiakasryhmäsi: ',
      },
    },
    authorized: {
      header: 'Valtuutus on tallennettu onnistuneesti',
      message: 'Valtuutus on voimassa vuoden, minkä jälkeen sinun on uusittava se tässä valtuuttamispalvelussa. Valtuuttamispalvelussa voi perua voimassaolevan valtuutuksen kyseiselle organisaatiolle.',
    },
    error: {
      userCanceled: {
        header: 'Peruutettu',
        message: 'Käyttäjä peruutti tapahtuman.',
      },
      invalidToken: 'Tunnistautuminen epäonnistui. Yritä uudelleen.',
      incompleteIdentification: {
        header: 'Vahva tunnistautuminen epäonnistui',
        description: 'Et voi antaa valtuutusta ilman vahvaa sähköistä tunnistautumista.',
      },
    },
    login: 'Kirjaudu sisään',
    register: 'Rekisteröidy',
    cancel: 'Peruuta',
    authorize: 'Valtuuta',
    revokeAuthorizations: 'Peru voimassaoleva valtuutus',
    return: 'Palaa',
    customerGroup: {
      adult: 'Aikuinen',
      child: 'Lapsi 13-17v',
      student: 'Opiskelija',
      senior: 'Aikuinen 70+',
    },
  },
  sv: {
    landing: {
      header: 'Tillgång till tjänster på någon annans vägnar',
      p1: 'Du håller på att ge fullmakt till ',
      p2: 'för att köpa HRT:s personliga biljetter för dig på dina vägnar. En förutsättning för att ge en fullmakt är att du har ett HRT-konto och att du har autentiserat dig starkt. Genom stark autentisering verifierar vi din hemkommun och identitet. Du kan autentisera dig med nätbankkoder eller med mobilcertifikat.',
      p3: 'Om du redan har ett HRT-konto, logga in med dina inloggningsuppgifter. Vid behov ber vi dig att autentisera dig starkt.',
      p4: 'Om du ännu inte har ett HRT-konto, skapa dig ett nytt. Efter att du har skapat och bekräftat ditt konto bör du autentisera dig starkt.',
    },
    strongIdentification: {
      success: {
        header: 'Ge fullmakt till ',
        info1: 'Nu kan du ge fullmakt till ',
        info2: 'för att köpa HRT:s personliga biljetter för dig på dina vägnar. Genom att ge en fullmakt förbinder du dig att följa ',
        infoLinkText: 'Allmänna rese- och biljettvillkor för HRT:s kollektiktrafik',
        infoLink: 'https://www.hsl.fi/sv/hrt/nya-anvandarvillkor/allmanna-rese-och-biljettvillkor-for-HRTs-kollektivtrafik',
        info3: 'Efter att du gett din fullmakt skickas en krypterad tagg till ',
        info4: ' som möjliggör köpet av biljetten.',
        info5: 'Fullmakten gäller i ett år. Varje gång ',
        info6: ' köper en personlig HSL-biljett för din räkning förnyas fullmaktens giltighet i ett år från datumet för att beställa biljetten. Du kan annulera fullmakten för ',
        info7: ' i den här fullmaktstjänsten.',
        authorize1: 'Jag ger fullmakt till',
        authorize2: ' för att köpa HRT:s personliga biljetter på mina vägnar.',
        customerGroupInfo: 'Dina kundgrupper: ',
      },
    },
    authorized: {
      header: 'Fullmakten har sparats',
      message: 'Fullmakten har sparats. Fullmakten gäller i ett år varefter du bör förnya fullmakten i denna tjänst. Du kan annulera giltiga fullmakter i den här fullmaktstjänsten.',
    },
    error: {
      userCanceled: {
        header: 'Avbryten session',
        message: 'Användaren har avbrytit sessionen.',
      },
      invalidToken: 'Taggen är felaktig eller har gått ut',
      incompleteIdentification: {
        header: 'Stark autentisering misslyckades',
        description: 'Du kan inte ge din fullmakt utan stark autentisering.',
      },
    },
    login: 'Logga in',
    register: 'Skapa konto',
    cancel: 'Avbryt',
    authorize: 'Ge fullmakt',
    revokeAuthorizations: 'Annulera fullmakten',
    return: 'Tillbaka',
    customerGroup: {
      adult: 'Vuxen',
      child: 'Barn 13-17 år',
      student: 'Studerande',
      senior: 'Vuxen 70+',
    },
  },
};

export default lang;
