/* eslint-disable no-console */
import {
  observable,
  action,
  computed,
  makeObservable,
  runInAction,
} from 'mobx';
import decode from 'jwt-decode';
import { stringify } from 'query-string';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from 'axios';
import {
  getOrganizationDetails,
  loginByCode,
  getCustomerGroups,
  authorizeOrganization,
  checkAndRevokeAuthorizations,
} from '../api/api';
import { StoreState, SupportedLocales } from '../types';
import SessionStore from './SessionStore';
import { handleRedirect } from '../utils/commonUtils';
import lang from '../locale/locale';
import appConfig from '../config';

export interface ICommonStore {
  state: StoreState;
  isLoading: boolean;
  queryState: string;
  locale: SupportedLocales;
  organizationId: string;
  nonce: string;
  redirectUrl: string;
  loginUrl: string;
  registerUrl: string;
  error: string;
  errorDescription: string;
  customerGroups: string[] | null;
  setError: (error: string, errorDescription: string) => void;
  cancelAndClear: () => void;
  initAppState: (queryState: string) => void;
  loginByCode: (code: string) => void;
  getCustomerGroups: () => void;
}

export class CommonStore implements ICommonStore {
  state: StoreState = 'idle';

  queryState: ICommonStore['queryState'] = '';

  locale: ICommonStore['locale'] = 'en';

  organizationId: ICommonStore['organizationId'] = '';

  nonce: ICommonStore['nonce'] = '';

  redirectUrl: ICommonStore['redirectUrl'] = '';

  loginUrl: ICommonStore['loginUrl'] = '';

  registerUrl: ICommonStore['registerUrl'] = '';

  error: ICommonStore['error'] = '';

  errorDescription: ICommonStore['errorDescription'] = '';

  customerGroups: ICommonStore['customerGroups'] = null;

  constructor() {
    makeObservable(this, {
      state: observable,
      queryState: observable,
      locale: observable,
      organizationId: observable,
      nonce: observable,
      redirectUrl: observable,
      loginUrl: observable,
      registerUrl: observable,
      error: observable,
      errorDescription: observable,
      customerGroups: observable,
      isLoading: computed,
      setError: action,
      cancelAndClear: action,
      initAppState: action,
    });
  }

  get isLoading(): boolean {
    return this.state === 'idle' || this.state === 'loading';
  }

  /**
   * Set app to error state
   */
  setError = (error: string, errorDescription: string): void => {
    this.error = error;
    this.errorDescription = errorDescription;
    this.state = 'error';
  };

  /**
   * Cancel and clear app
   */
  cancelAndClear = (): void => {
    SessionStore.setAccessToken('');
    SessionStore.setIdToken('');
    SessionStore.setOrganizationName('');
    const baseRedirectUrl = new URL(this.redirectUrl);
    baseRedirectUrl.searchParams.append(
      'error',
      lang[this.locale].error.userCanceled.header,
    );
    baseRedirectUrl.searchParams.append(
      'error_description',
      lang[this.locale].error.userCanceled.message,
    );
    baseRedirectUrl.searchParams.append('state', this.queryState);
    window.location.assign(baseRedirectUrl.href);
  };

  /**
   * Init app state
   */
  initAppState = async (queryState: string): Promise<void> => {
    this.state = 'loading';

    // Handle state query parameter
    try {
      const qs = JSON.parse(atob(decodeURIComponent(queryState)));

      const {
        organizationId, locale, redirectUrl, nonce,
      } = qs;

      if (organizationId) this.organizationId = organizationId;
      if (redirectUrl) this.redirectUrl = redirectUrl;
      if (nonce) this.nonce = nonce;
      this.queryState = queryState;

      if (locale) {
        switch (locale) {
          case 'en':
          case 'fi':
          case 'sv':
            this.locale = locale;
            break;
          default:
            this.locale = 'en';
        }
      }
    } catch (e: unknown) {
      this.setError('Invalid query', 'Unable to parse state');
    }

    // Setup URL's
    this.loginUrl = `${appConfig.OPENID_CONNECT_URL}/openid/auth?${stringify({
      client_id: appConfig.OPENID_CONNECT_CLIENT_ID,
      redirect_uri: `${appConfig.BASE_URL}/authcallback`,
      response_type: 'code',
      scope: 'openid',
      state: queryState,
      ui_locales: this.locale,
      claims: JSON.stringify({
        id_token: {
          'https://oneportal.trivore.com/claims/strong_identification': {
            essential: false,
          },
        },
      }),
    })}`;

    const regex = /\?/g;
    const found = appConfig.HSLID_REGISTER_URL.match(regex);
    if (found) {
      this.registerUrl = `${appConfig.HSLID_REGISTER_URL}&ui_locales=${this.locale}&state=${queryState}`;
    } else {
      this.registerUrl = `${appConfig.HSLID_REGISTER_URL}?${stringify({
        ui_locales: this.locale,
        returnTo: `${appConfig.BASE_URL}?state=${queryState}`,
      })}`;
    }

    // Init Organization
    try {
      if (this.organizationId) {
        const { data } = await getOrganizationDetails(this.organizationId);
        runInAction(() => {
          SessionStore.setOrganizationName(data.organizationName);
          this.state = 'success';
        });
      } else {
        this.setError('Järjestelmävirhe', 'Initialisaatio epäonnistui.');
      }
    } catch (e: unknown | Error | AxiosError) {
      console.error(e);
      runInAction(() => {
        if (axios.isAxiosError(e)) {
          this.setError(
            e.response?.data?.error,
            e.response?.data?.error_description,
          );
        }
      });
    }
  };

  /**
   * When user has successfully logged in in HSL ID,
   * user is returned to our app with query parameter "code".
   *
   * This "code" is used to get access_token and id_token from HSL ID.
   *
   * Therefore, if code is set, fetch tokens.
   */
  loginByCode = async (code: string): Promise<void> => {
    try {
      const { data } = await loginByCode(code);

      runInAction(() => {
        if (!data || !data.access_token || !data.id_token) {
          // history.replace(`/authcallback?state=${queryState}`);
          this.setError('Login failed', 'No access tokens received from HSLID');
          return;
        }

        SessionStore.setIdToken(data.id_token);
        SessionStore.setAccessToken(data.access_token);

        const decoded = decode(data.id_token);

        if (
          // @ts-ignore
          decoded[
            'https://oneportal.trivore.com/claims/strong_identification'
          ]
          // @ts-ignore
          && decoded['https://oneportal.trivore.com/claims/strong_identification']
            .identified
          // @ts-ignore
          && (decoded['https://oneportal.trivore.com/claims/strong_identification']
            .method === 'SUOMI_FI'
            // @ts-ignore
            || decoded[
              'https://oneportal.trivore.com/claims/strong_identification'
            ].method === 'IN_PERSON')
        ) {
          window.location.assign(
            `${appConfig.BASE_URL}/success?state=${this.queryState}`,
          );
        } else {
          window.location.assign(
            `${
              appConfig.OPENID_CONNECT_URL
            }/openid/strongidentification?${stringify({
              access_token: SessionStore.accessToken,
              successRedirectUri: `${appConfig.BASE_URL}/success?state=${this.queryState}`,
              failureRedirectUri: `${appConfig.BASE_URL}/fail?state=${this.queryState}`,
            })}`,
          );
        }
      });
    } catch (e: unknown | Error | AxiosError) {
      console.error(e);
      runInAction(() => {
        if (axios.isAxiosError(e)) {
          this.setError(
            e.response?.data?.error,
            e.response?.data?.error_description,
          );
        }
      });
    }
  };

  /**
   * Get customer groups
   */
  getCustomerGroups = async (): Promise<void> => {
    if (!SessionStore.idToken) {
      this.setError('invalid_token', lang[this.locale].error.invalidToken);
      return;
    }
    try {
      const { data } = await getCustomerGroups(SessionStore.idToken);
      runInAction(() => {
        this.customerGroups = data.customerGroups;
      });
    } catch (e: unknown | Error | AxiosError) {
      console.error(e);
      runInAction(() => {
        if (axios.isAxiosError(e)) {
          this.setError(
            e.response?.data?.error,
            e.response?.data?.error_description,
          );
        }
      });
    }
  };

  /**
   * Authorize organization
   */
  authorizeOrganization = async (): Promise<void> => {
    if (!SessionStore.idToken) {
      this.setError('invalid_token', lang[this.locale].error.invalidToken);
      return;
    }
    try {
      const { data } = await authorizeOrganization(this.organizationId, SessionStore.idToken);
      runInAction(() => {
        const authorizedUrl = appConfig.BASE_NAME
          ? `${appConfig.BASE_NAME}/authorized?state=${this.queryState}&token=${data}`
          : `/authorized?state=${this.queryState}&token=${data}`;
        window.location.assign(authorizedUrl);
      });
    } catch (e: unknown | Error | AxiosError) {
      console.error(e);
      runInAction(() => {
        if (axios.isAxiosError(e)) {
          this.setError(
            e.response?.data?.error,
            e.response?.data?.error_description,
          );
        }
      });
    }
  };

  /**
   * Check and revoke authorizations
   */
  checkAndRevokeAuthorizations = async (): Promise<void> => {
    if (!SessionStore.idToken) {
      this.setError('invalid_token', lang[this.locale].error.invalidToken);
      return;
    }
    try {
      await checkAndRevokeAuthorizations(
        this.organizationId,
        SessionStore.idToken,
      );
      runInAction(() => {
        handleRedirect(this.redirectUrl, this.queryState);
      });
    } catch (e: unknown | Error | AxiosError) {
      console.error(e);
      runInAction(() => {
        if (axios.isAxiosError(e)) {
          this.setError(
            e.response?.data?.error,
            e.response?.data?.error_description,
          );
        }
      });
    }
  };
}
