/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toJS, autorun, set } from 'mobx';
import { parse } from 'query-string';

/**
 * Automatically saves store state into sessionStorage
 */
export const storeAutoSave = (
  _this: any,
  storeName: string,
  omittedProps?: string[],
) => {
  const storedJson = sessionStorage.getItem(storeName);
  if (storedJson) {
    set(_this, JSON.parse(storedJson));
  }
  autorun(() => {
    const storeToSave = toJS(_this);
    if (omittedProps) {
      omittedProps?.forEach((prop) => {
        delete storeToSave[prop];
      });
    }
    sessionStorage.setItem(storeName, JSON.stringify(storeToSave));
  });
};

export const handleRedirect = (redirectUrl: string, queryState?: string) => {
  const queryParams = parse(window.location.search);
  const regex = /\?/g;
  const found = !!redirectUrl.match(regex);
  const getUrl = (isFound: boolean) => {
    if (isFound) {
      return `${redirectUrl}&token=${queryParams.token}${
        queryState ? `&state=${queryState}` : ''
      }`;
    }
    return `${redirectUrl}?token=${queryParams.token}${
      queryState ? `&state=${queryState}` : ''
    }`;
  };
  window.location.assign(getUrl(found));
};
