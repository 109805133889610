/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';

type Props = {
  primary?: boolean;
  disabled?: boolean;
};

const HslButton = ({ primary, disabled, ...props }: Props & Record<string, any>): JSX.Element => {
  if (disabled) {
    return (
      <div>
        <StyledDisabledButton type="button" primary={primary} disabled {...props} />
      </div>
    );
  }
  return (
    <div>
      <StyledButton type="button" primary={primary} {...props} />
    </div>
  );
};

export default HslButton;

const StyledButton = styled.button`
  display: block;
  margin: 15px 0;
  user-select: none;
  cursor: pointer;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props: Props) => (props.primary ? 'rgb(0, 122, 201)' : 'rgb(136, 136, 136)')};
  background-color: ${(props: Props) => (props.primary ? 'rgb(0, 122, 201)' : 'rgb(255, 255, 255)')};
  color: ${(props: Props) => (props.primary ? 'white' : 'rgb(0, 122, 201)')};
  border-radius: 40px;
  padding-left: 25px;
  padding-right: 25px;
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: normal;
  padding-top: 8px;
  padding-bottom: 8px;
  letter-spacing: -0.6px;
  &:hover,
  &:focus {
    border-color: ${(props: Props) => (props.primary ? 'rgb(0, 98 ,161)' : 'rgb(0, 122, 201)')};
    background-color: ${(props: Props) => (props.primary ? 'rgb(0, 98 ,161)' : 'rgb(255, 255, 255)')};
  }
`;

const StyledDisabledButton = styled(StyledButton)`
  border-color: rgb(187, 187, 187);
  background-color: ${(props: Props) => (props.primary ? 'rgb(187, 187, 187)' : 'rgb(255, 255, 255)')};
  color: rgb(255, 255, 255);
  cursor: not-allowed;
  &:hover,
  &:focus {
    border-color: rgb(187, 187, 187);
    background-color: ${(props: Props) => (props.primary ? 'rgb(187, 187, 187)' : 'rgb(255, 255, 255)')};
  }
`;
