import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.header`
  margin: 15px;
`;

const StyledLogo = styled.img`
  height: 50px;
`;

const Header = (): JSX.Element => (
  <StyledHeader>
    <StyledLogo alt="HSL Logo" src="./images/hsl-logo.png" />
  </StyledHeader>
);

export default Header;
