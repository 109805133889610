/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { parse } from 'query-string';
import { Spinner } from './components';
import {
  Error,
  AuthCallback,
  Landing,
  Success,
  Fail,
  Authorized,
} from './screens';
import { useStore } from './stores';
import config from './config';

export const Routes = observer(() => {
  const commonStore = useStore('commonStore');
  const { setError, initAppState } = commonStore;

  const { state, error, error_description } = parse(window.location.search);

  /**
   * Application initialization logic
   */
  useEffect(() => {
    // Handle state query param
    if (!state) {
      setError('Invalid query', 'Required parameter "state" is missing.');
    } else if (error && error_description) {
      // Set error from query params
      setError(error as string, error_description as string);
    } else {
      initAppState(state as string);
    }
  }, [state, error, error_description, setError, initAppState]);

  // Show spinner when app is loading
  if (commonStore.isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  // Show error screen if error is present
  if (commonStore.error && commonStore.errorDescription) return <Error />;

  // Set error for invalid app state
  if (
    !commonStore.isLoading
    && (!commonStore.queryState
      || !commonStore.locale
      || !commonStore.organizationId
      || !commonStore.redirectUrl
      || !commonStore.nonce)
  ) {
    setError('Järjestelmävirhe', 'Initialisaatio epäonnistui.');
  }

  return (
    <Router basename={config.BASE_NAME}>
      <Switch>
        <Route path="/authcallback">
          <AuthCallback />
        </Route>
        <Route path="/success">
          <Success />
        </Route>
        <Route path="/fail">
          <Fail />
        </Route>
        <Route path="/authorized">
          <Authorized />
        </Route>
        <Route path="/">
          <Landing />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
});
