import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { parse } from 'query-string';
import { useStore } from '../stores';

export const AuthCallback = observer(() => {
  const commonStore = useStore('commonStore');

  const { code } = parse(window.location.search);

  useEffect(() => {
    if (code) {
      commonStore.loginByCode(code as string);
    }
  }, [code, commonStore.loginByCode]);

  return null;
});
