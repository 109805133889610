/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';

type Props = {
  title: string;
};

const StyledCheckbox = ({ title, ...props }: Props & Record<string, any>): JSX.Element => (
  <Container>
    <CheckboxContainer>
      <Checkbox type="checkbox" {...props} />
    </CheckboxContainer>
    <LabelContainer>
      <Label>
        {title}
      </Label>
    </LabelContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
`;

const CheckboxContainer = styled.div`
  flex: 0 0 auto;
`;

const LabelContainer = styled.div`
  flex: 1 1 auto;
`;

const Label = styled.label`
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0px;
  color: rgb(102, 102, 102);
  cursor: pointer;
`;

const Checkbox = styled.input`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(136, 136, 136);
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  margin-right: 10px;
`;

export default StyledCheckbox;
