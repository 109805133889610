import React from 'react';
import SessionStore from './SessionStore';
import { CommonStore } from './CommonStore';

export const stores = Object.freeze({
  sessionStore: SessionStore,
  commonStore: new CommonStore(),
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;
