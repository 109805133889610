import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores';
import {
  Button,
  Header,
  Content,
} from '../components';
import lang from '../locale/locale';

export const Landing = observer(() => {
  const commonStore = useStore('commonStore');
  const sessionStore = useStore('sessionStore');

  // Translations for current locale
  const t = useMemo(() => lang[commonStore.locale], [commonStore.locale]);

  const handleLogin = () => {
    window.location.assign(commonStore.loginUrl);
  };

  const handleRegister = () => {
    window.location.assign(commonStore.registerUrl);
  };

  const handleCancel = () => {
    commonStore.cancelAndClear();
  };

  return (
    <div>
      <Header />
      <main>
        <Content>
          <h1>{t.landing.header}</h1>
          <p>{`${t.landing.p1} ${sessionStore.organizationName}${t.landing.p2}`}</p>
          <p>{t.landing.p3}</p>
          <p>{t.landing.p4}</p>
          <Button primary onClick={handleLogin}>
            {t.login}
          </Button>
          <Button onClick={handleRegister}>{t.register}</Button>
          <Button onClick={handleCancel}>{t.cancel}</Button>
        </Content>
      </main>
    </div>
  );
});
