import styled from 'styled-components';

const StyledContent = styled.section`
  max-width: 350px;
  margin: 15px;
  padding: 15px;
  background: white;
  color: #474747;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
`;

export default StyledContent;
